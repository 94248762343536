import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  Renderer2,
  signal,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import {
  CookieConsentService,
  FooterComponent,
  LinkService,
  LocalIdService,
  NavbarComponent,
  SubscriptionPlanExpiringBannerComponent,
  UserService,
} from '@app/core';
import { APP_ROUTES, GlobalLoadingIndicatorComponent, Link } from '@app/shared';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, mergeMap } from 'rxjs';
import { APP_LANGUAGE, languageLocalStorageKey } from './app.constants';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MatIconModule } from '@angular/material/icon';

type NavigationEvent = NavigationEnd | NavigationStart;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    MatSnackBarModule,
    RouterOutlet,
    MatButtonModule,
    FooterComponent,
    MatProgressBarModule,
    GlobalLoadingIndicatorComponent,
    NavbarComponent,
    SubscriptionPlanExpiringBannerComponent,
    CommonModule,
    TranslateModule,
    MatIconModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly #translateService = inject(TranslateService);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #localIdService = inject(LocalIdService);
  readonly #renderer2 = inject(Renderer2);
  readonly #router = inject(Router);
  readonly #gtmService = inject(GoogleTagManagerService);
  readonly #linkService = inject(LinkService);
  readonly #cookieConsentService = inject(CookieConsentService);

  public isUserValidated = false;

  private static readonly DEFAULT_LOCAL = 'en-EN';
  private static readonly DEFAULT_LANGUAGE = APP_LANGUAGE.ENGLISH;
  private static readonly USERCENTRICS_ANALYTICS_EVENT = 'Analytics';
  private static readonly ROUTES_WITHOUT_AUTHENTICATION = [
    APP_ROUTES.SHARED_PROJECT,
    APP_ROUTES.FREE_TO_PLAY,
  ];

  public readonly footerLinks: WritableSignal<Link[]> = signal([]);

  readonly #destroyRef = inject(DestroyRef);

  readonly #localByLanguage = new Map<APP_LANGUAGE, string>([
    [APP_LANGUAGE.ENGLISH, AppComponent.DEFAULT_LOCAL],
    [APP_LANGUAGE.GERMAN, 'de-DE'],
  ]);

  public ngOnInit(): void {
    this.#initializeTranslations();
    this.#initAuthentication();
    this.#cookieConsentService.init(this.#renderer2);
    this.#initAnalyticsTracking();
  }

  #initializeTranslations(): void {
    this.#translateService.setDefaultLang(AppComponent.DEFAULT_LANGUAGE);
    const previouslySelectedLanguage = localStorage.getItem(
      languageLocalStorageKey
    );

    if (previouslySelectedLanguage) {
      this.#translateService.use(previouslySelectedLanguage);
    } else {
      const browserLanguage = this.#translateService.getBrowserLang() || '';

      const parsedLanguage = Object.keys(APP_LANGUAGE)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map(key => (APP_LANGUAGE as any)[key] as string)
        .includes(browserLanguage)
        ? browserLanguage
        : AppComponent.DEFAULT_LANGUAGE;

      this.#translateService.use(parsedLanguage);
    }
    this.#translateService.onLangChange
      ?.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(languageChange => {
        const local = this.#localByLanguage.get(
          languageChange.lang as APP_LANGUAGE
        );

        if (local) {
          this.#localIdService.setLocale(local);
        } else {
          this.#localIdService.setLocale(AppComponent.DEFAULT_LOCAL);
        }

        this.#cookieConsentService.updateLanguage(languageChange.lang);
        this.determineFooterLinks(languageChange.lang);
      });
  }

  private determineFooterLinks(lang: string) {
    const footerLinks: Link[] = [
      this.#linkService.getPrivacyNoticeLink(lang),
      this.#linkService.getTermsAndConditionsLink(lang),
      this.#linkService.getImprintLink(lang),
    ];
    this.footerLinks.update(() => footerLinks);
  }

  async #initAuthentication(): Promise<void> {
    combineLatest([
      this.#router.events.pipe(
        filter(
          event =>
            event instanceof NavigationEnd || event instanceof NavigationStart
        )
      ),
      this.#authService.isAuthenticated$,
    ])
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(([route, isAuthenticated]) => {
        const url = (route as NavigationEvent).url;
        const isRouteWithoutAuthentication =
          AppComponent.ROUTES_WITHOUT_AUTHENTICATION.some(item =>
            url.toLowerCase().includes(item.toLowerCase())
          );

        if (url === '/error') {
          return;
        }

        if (isRouteWithoutAuthentication) {
          return;
        }

        if (isAuthenticated) {
          this.#authService
            .getAccessTokenSilently()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(accessToken => {
              sessionStorage.setItem('access_token', accessToken || '');
            });

          this.#userService.validateUser();
        } else if (!isRouteWithoutAuthentication) {
          this.#authService.loginWithRedirect();
        }
      });

    this.#authService.error$
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        filter(
          e =>
            e instanceof GenericError &&
            (e.error === 'login_required' || e.error === 'invalid_grant')
        ),
        mergeMap(() => this.#authService.logout())
      )
      .subscribe();
  }

  #initAnalyticsTracking(): void {
    this.#router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: event.url,
          };
          this.#gtmService.pushTag(gtmTag);
        }
      });
  }
}
